@font-face {
  font-family: 'LeagueGothic';
  src: url('../public/LeagueGothic-Regular.otf') format('opentype');
  /* You may need to adjust the path based on your project structure */
}

@font-face {
  font-family: 'Anton';
  src: url('../public/Anton-Regular.otf') format('opentype');
  /* You may need to adjust the path based on your project structure */
}

/* * {
  font-family: 'Anton', sans-serif;
} */

.bigtitles {
  font-family: 'Anton', sans-serif !important;
}

.messages {
  font-family: Arial, Helvetica, sans-serif !important
}

.messages::placeholder {
  font-size: 16px;
  /* Adjust the font size as needed */
  color: grey;
  /* Customize the color as well */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  background-color: black;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fighterCard {
  max-height: '70vh';
  overflow: hidden;
}

.fighterCard::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for WebKit browsers (Chrome, Safari, etc.) */
}

/* .fighterCard::-webkit-scrollbar {
  width: 0.4em;
}

.fighterCard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
}

.fighterCard::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 1px solid slategrey;
  border-radius: '8px',
} */